import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';

export class AppointmentView {
    id = null;
    data = null;
    isLoading = false;
    isReady = false;
    hasPendingReviewCommunications = false;
    cancelAppointmentGet = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Appointments.get(
                that.id,
                (c) => { that.cancelAppointmentGet = c; }
            )
                .then(({ data }) => {
                    const customerIds = [];

                    if (data.customerId) {
                        customerIds.push(data.customerId);
                    }
                    if (data.primaryContact && data.primaryContact.id !== data.customerId) {
                        customerIds.push(data.primaryContact.id);
                    }

                    api.Communications.search({
                        parameters: [
                            { field: 'CustomerId', operator: 'Contains', value: customerIds.join(',') },
                            { field: 'Status', value: 'Queued' },
                            { field: 'Direction', value: 'Outbound' },
                            { field: 'DeactivatedDateUtc', value: null },
                            { field: 'FailedDateUtc', value: null },
                            { field: 'DeliveredDateUtc', value: null },
                            { field: 'Reference', operator: 'Contains', value: 'AppointmentReview,WorkOrderReview' },
                            { field: 'ScheduledSendDateTimeLocal', operator: '>', value: moment().format('YYYY-MM-DDTHH:mm:ss') },
                        ],
                        includeTotalCount: true,
                        includeResult: false,
                    })
                        .then(({ data: communicationData }) => {
                            that.hasPendingReviewCommunications = !!communicationData && communicationData.total > 0;
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                        .finally(() => {
                            that.isLoading = false;
                        })

                    that.data = data;
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.hasPendingReviewCommunications = false;
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelAppointmentGet)) {
            this.cancelAppointmentGet();
            this.cancelAppointmentGet = null;
        }
    }

    get start() {
        if (!this.data) return null;
        return moment(this.data.start);
    }

    get startUtc() {
        if (!this.data) return null;
        return moment.utc(this.data.startUtc);
    }

    get end() {
        if (!this.data) return null;
        return moment(this.data.end);
    }

    get endUtc() {
        if (!this.data) return null;
        return moment(this.data.endUtc);
    }
}

decorate(AppointmentView, {
    id: observable,
    data: observable,
    hasPendingReviewCommunications: observable,
    isLoading: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    clear: action,
    start: computed,
    startUtc: computed,
    end: computed,
    endUtc: computed,
})

export default createContext(new AppointmentView());