import React, { Component } from 'react';
import { Popup } from 'devextreme-react';
import moment, { isMoment } from 'moment';

import PresetCommunicationSchedule from './Preset';
import PickerCommunicationSchedule from './Picker';
import ReasonCommunicationSchedule from './Reason';

import * as fn from '../../../../utilities/_functions';

import './_index.scss';

const COMMUNICATION_SCHEDULE_POPUP_ID = '__communication-schedule-popup';
const COMMUNICATION_SCHEDULE_MODAL_GRID_WIDTH = 480;
const COMMUNICATION_SCHEDULE_MORNING_HOUR = 8;
const COMMUNICATION_SCHEDULE_AFTERNOON_HOUR = 13;
const COMMUNICATION_SCHEDULE_EVENING_HOUR = 19;
const COMMUNICATION_SCHEDULE_PRESET_OPTIONS = [
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_MORNING_HOUR, minute: 0, second: 0, millisecond: 0 }),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_AFTERNOON_HOUR, minute: 0, second: 0, millisecond: 0 }),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_EVENING_HOUR, minute: 0, second: 0, millisecond: 0 }),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_MORNING_HOUR, minute: 0, second: 0, millisecond: 0 }).add(1, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_AFTERNOON_HOUR, minute: 0, second: 0, millisecond: 0 }).add(1, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_EVENING_HOUR, minute: 0, second: 0, millisecond: 0 }).add(1, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_MORNING_HOUR, minute: 0, second: 0, millisecond: 0 }).add(3, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_AFTERNOON_HOUR, minute: 0, second: 0, millisecond: 0 }).add(3, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_EVENING_HOUR, minute: 0, second: 0, millisecond: 0 }).add(3, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_MORNING_HOUR, minute: 0, second: 0, millisecond: 0 }).add(7, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_AFTERNOON_HOUR, minute: 0, second: 0, millisecond: 0 }).add(7, 'days'),
    moment().startOf('day').set({ hour: COMMUNICATION_SCHEDULE_EVENING_HOUR, minute: 0, second: 0, millisecond: 0 }).add(7, 'days'),
];

export default class CommunicationScheduleModal extends Component {
    constructor(props) {
        super(props);
        this.popupRef = React.createRef();
        this.state = {
            display: false,
            zIndex: null,
            mode: null,
            type: null,
            scheduledSendDateTimeLocal: null,
            reference: null,
        };
        this.changeMode = this.changeMode.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleScheduled = this.handleScheduled.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    show = (options) => {
        const zIndex = fn.getHighestZIndex() + 1;
        const defaults = {
            dateTimeLocal: null,
            type: null,
            mode: null,
            reference: null,
        };
        options = { ...defaults, ...options };
        options.mode = options.mode ? options.mode : this.getMode(options.dateTimeLocal);
        this.setState({ type: options.type, display: true, zIndex: zIndex, mode: options.mode, scheduledSendDateTimeLocal: options.dateTimeLocal, reference: options.reference });
    }

    close = () => {
        this.handleClose();
    }

    changeMode = mode => {
        this.handleModeChange(mode);
    }

    handleModeChange = mode => {
        this.setState({ mode });
    }

    handleShowing = event => {
        const popup = document.getElementById(COMMUNICATION_SCHEDULE_POPUP_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleClose = event => {
        if (this.props.onClose) { this.props.onClose(); }
        this.setState({ type: null, mode: null, display: false, zIndex: null, scheduledSendDateTimeLocal: null, reference: null });
    }

    handleSelect = (event, result) => {
        if (fn.isFunction(this.props.onSelect)) {
            const { reference } = this.state;
            this.props.onSelect(event, result, reference);
        }

        this.handleClose(event);
    }

    handleScheduled = (event, reason) => {
        if (fn.isFunction(this.props.onScheduled)) {
            this.props.onScheduled(event, reason);
        }

        this.handleClose(event);
    }

    getMode = (scheduledSendDateTimeLocal) => {
        return !scheduledSendDateTimeLocal || COMMUNICATION_SCHEDULE_PRESET_OPTIONS.some(o => o.isSame(scheduledSendDateTimeLocal)) ? 'preset' : 'picker';
    }

    getDayDescription = (dateTime, daysOffset) => {
        let prefix;
        const today = moment().startOf('day');

        if (fn.isNullOrUndefined(daysOffset)) {
            daysOffset = dateTime.diff(today, 'days');
        }

        if (daysOffset === 0 || daysOffset === 1) {
            prefix = fn.getDatePastOrFutureDayDescription(dateTime, today, { today: 'This' });
        }
        else if (daysOffset >= 2 && daysOffset < 7) {
            prefix = dateTime.format('dddd');
        }
        else if (daysOffset >= 7 && daysOffset < 14) {
            prefix = `Next ${dateTime.format('dddd')}`;
        }

        return `${prefix} ${fn.getTimeMorningAfternoonEveningDescription(dateTime).toLowerCase()}`.trim();
    }

    getTimeDescription = (dateTime) => {
        const now = moment();
        return `${fn.formatDateWithOrWithoutYear(dateTime, now, false)} @ ${fn.formatTime(dateTime)}`;
    }

    get isVisible() {
        return this.state.display;
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        let { type, mode, scheduledSendDateTimeLocal, reference } = this.state;

        const contentProps = {
            type: type,
            scheduledSendDateTimeLocal: scheduledSendDateTimeLocal,
            reference: reference,
            getDayDescription: this.getDayDescription,
            getTimeDescription: this.getTimeDescription,
            changeMode: this.changeMode,
            onSelect: this.handleSelect,
            onScheduled: this.handleScheduled,
            onClose: this.handleClose
        };

        const presetProps = { ...contentProps, ...{ presets: COMMUNICATION_SCHEDULE_PRESET_OPTIONS } }

        if (mode) {
            switch (mode.toLowerCase()) {
                case 'preset':
                    return <PresetCommunicationSchedule {...presetProps} />;

                case 'picker':
                    return <PickerCommunicationSchedule {...contentProps} />;

                case 'reason':
                    return <ReasonCommunicationSchedule {...contentProps} />;

                default:
                    return;
            }
        }
    }

    render() {
        return <>
            <Popup
                ref={this.popupRef}
                wrapperAttr={{ id: COMMUNICATION_SCHEDULE_POPUP_ID, class: 'dx-popup-communication-schedule' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                width={COMMUNICATION_SCHEDULE_MODAL_GRID_WIDTH}
                height={'auto'}
                dragEnabled={false}
                dragOutsideBoundary={false}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onShowing={this.handleShowing}
                onHiding={this.handleClose}
            />
        </>
    }
}